import React from 'react';
import DesktopLayout from "../layouts/Desktop";

const Contact = () => {
  return (
    <DesktopLayout>
      <p>Send us a message!</p>
    </DesktopLayout>
  );
};

export default Contact;
